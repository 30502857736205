import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { HelpPopover } from "../components/HelpPopover";
import * as Yup from "yup";
import { Formik, Form, useFormik } from "formik";
import { useAuth } from "../contexts/useAuth";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import supabase from "../config/supabaseClient";
import { set } from "lodash";
import { ExpandMore } from "@mui/icons-material";

// Yup Validation Schema
const schemaEntity = Yup.object().shape({
  ra_is_standard_address: Yup.boolean().required("Required"),
  ra_lot_address: Yup.string(),
  ra_street_address: Yup.string().required("Required"),
  ra_street_name: Yup.string().required("Required"),
  ra_unit: Yup.string(),
  ra_city: Yup.string().required("Required"),
  ra_country: Yup.string(),
  ra_jurisdiction: Yup.string().required("Required").oneOf(["ON"], 'Province must be "ON"'),
  ra_postal_code: Yup.string().required("Required"),
});

const schemaEntity_isLotAddress = Yup.object().shape({
  ra_is_standard_address: Yup.boolean().required("Required"),
  ra_lot_address: Yup.string(),
  ra_street_address: Yup.string(),
  ra_street_name: Yup.string(),
  ra_unit: Yup.string(),
  ra_city: Yup.string().required("Required"),
  ra_country: Yup.string(),
  ra_jurisdiction: Yup.string().required("Required").oneOf(["ON"], 'Province must be "ON"'),
  ra_postal_code: Yup.string().required("Required"),
});

// =========================================================
// Update Entity w/ activeStep
// =========================================================
async function updateEntity(eid, entityData, step) {
  entityData.last_step = step;
  console.log("from update function eid: ", eid);
  console.log("from update function updated step: ", entityData.last_step);
  console.log("from update function entityData: ", entityData);

  const { data, error } = await supabase.from("entity").update(entityData).eq("id", eid).select().single();

  if (error) {
    console.log("error", error);
  }

  if (data) {
    console.log("updateEntity Function Data result: ", data);
    return data;
  }
}

export default function Step3({ activeStep, setActiveStep, handleNext, handleBack }) {
  const { user } = useAuth();
  const { eid } = useParams();

  const formik = useFormik({
    initialValues: {
      name: "",
      legal_element_ending: "Ltd",
      ra_lot_address: "",
      ra_jurisdiction: "ON",
      ra_street_address: "",
      ra_street_name: "",
      ra_unit: "",
      ra_is_standard_address: true,
      ra_city: "",
      ra_postal_code: "",
      ra_country: "Canada",
      inc_is_sole_shareholder: true,
    },

    validationSchema: schemaEntity,
    // onSubmit: async (values) => {
    //   console.log("values", values);
    //   const { error } = await logIn(values);
    //   if (error) {
    //     alert(error.message);
    //   }
    //   // handleSignIn(values);
    // },
  });

  // const validationSchema = formik.values.ra_is_standard_address ? schemaEntity : schemaEntity_isLotAddress;
  const handleClickNext = async () => {
    if (!formik.values.ra_is_standard_address) {
      formik.validationSchema = schemaEntity_isLotAddress;
    }

    console.log(formik.validationSchema);

    // Fix Null Values - should not happen, if it does, must update default values in Supabase Entity Table
    // REFACTOR: Move to a function
    if (!formik.values.ra_unit) {
      formik.setFieldValue("ra_unit", "");
    }

    if (!formik.values.ra_city) {
      formik.setFieldValue("ra_city", "");
    }

    if (!formik.values.ra_jurisdiction) {
      formik.setFieldValue("ra_jurisdiction", "ON");
    }

    if (!formik.values.ra_lot_address) {
      formik.setFieldValue("ra_lot_address", "");
    }

    if (!formik.values.ra_country) {
      formik.setFieldValue("ra_country", "Canada");
    }

    // Validate Formik for errors and return if errors
    if (Object.keys(formik.errors).length > 0) {
      console.log("formik errors", formik.errors);
      formik.setTouched(formik.errors);
      return;
    }

    // Call the updateEntity function to update the entity in supabase
    updateEntity(eid, formik.values, 3).then((data) => {
      console.log("Step1 returned data", data);
      // setEntityData(data);
      formik.setValues(data);
      setActiveStep(data.last_step);
    });
  };

  const handleClickBack = () => {
    handleBack();
  };

  useEffect(() => {
    // Get Entity Data
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log(error);
        return;
      }

      if (data.length > 0) {
        console.log("getEntityData data", data);
        formik.setValues(data[0]);
        // setActiveStep(data.last_step);
      }
    };
    getEntityData();
  }, [eid]);

  return (
    <>
      {/* <Formik
        initialValues={{
          name: "",
          legal_element_ending: "Ltd",
          ra_lot_address: "",
          ra_jurisdiction: "ON",
          ra_street_address: "",
          ra_street_name: "",
          ra_is_standard_address: true,
          ra_city: "",
          ra_postal_code: "",
          ra_country: "Canada",
          inc_is_sole_shareholder: true,
        }}
        validationSchema={formik.values.ra_is_standard_address ? schemaEntity : schemaEntity_isLotAddress}
      > */}
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant="subtitle1">Enter an Ontario address to be used as your company&apos;s Registered Office address</Typography>
        <HelpPopover>
          <Stack spacing={1} p={3} maxWidth={300}>
            <Typography sx={{ p: 1 }}>
              Corporations in Ontario are required to designate a physical location in Ontario as its registered office. All governmental
              correspondence will be sent to that address and it cannot be a PO Box or a virtual office.
            </Typography>
            <Typography sx={{ p: 1 }}>It can be your home or office address as long as it’s a physical place that can receive mail.</Typography>
          </Stack>
        </HelpPopover>
      </Box>

      <Stack>
        <Box flexDirection={{ xs: "column", sm: "row" }} display="flex" columnGap={2} rowGap={2} mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="ra_is_standard_address"
                id="ra_is_standard_address"
                checked={!formik.values.ra_is_standard_address}
                value={formik.values.ra_is_standard_address}
                onChange={(e) => formik.setFieldValue("ra_is_standard_address", !e.target.checked)}
              />
            }
            label="This is a Lot/Concession address"
          />
        </Box>

        <Box flexDirection={{ xs: "column", sm: "row" }} columnGap={2} rowGap={2} display={formik.values.ra_is_standard_address ? "flex" : "none"}>
          <FormControl sx={{ width: "25ch" }}>
            <TextField
              fullWidth
              name="ra_street_address"
              label="Street Number"
              // fullWidth={{ sm: true, md: false }}
              value={formik.values.ra_street_address}
              onChange={formik.handleChange}
              error={formik.touched.ra_street_address && Boolean(formik.errors.ra_street_address)}
              helperText={formik.touched.ra_street_address && formik.errors.ra_street_address}
              onBlur={formik.handleBlur}
              // sx={{ width: "25ch" }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              name="ra_street_name"
              label="Address"
              fullWidth
              // fullWidth={{ sm: true, md: false }}
              value={formik.values.ra_street_name}
              onChange={formik.handleChange}
              error={formik.touched.ra_street_name && Boolean(formik.errors.ra_street_name)}
              helperText={formik.touched.ra_street_name && formik.errors.ra_street_name}
              onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormControl>
            <TextField
              name="ra_unit"
              label="Unit"
              // fullWidth={{ sm: true, md: false }}
              value={formik.values.ra_unit}
              onChange={formik.handleChange}
              error={formik.touched.ra_unit && Boolean(formik.errors.ra_unit)}
              helperText={formik.touched.ra_unit && formik.errors.ra_unit}
              onBlur={formik.handleBlur}
            />
          </FormControl>
        </Box>
        <Box display={formik.values.ra_is_standard_address ? "none" : "flex"}>
          {/* add conditional display: block / none for checkbox */}

          <TextField
            fullWidth
            name="ra_lot_address"
            label="Lot Address"
            value={formik.values.ra_lot_address}
            onChange={formik.handleChange}
            error={formik.touched.ra_lot_address && Boolean(formik.errors.ra_lot_address)}
            helperText={formik.touched.ra_lot_address && formik.errors.ra_lot_address}
          />
        </Box>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems={"flex-start"} marginTop={2}>
          <TextField
            name="ra_city"
            label="City"
            fullWidth
            value={formik.values.ra_city}
            onChange={formik.handleChange}
            error={formik.touched.ra_city && Boolean(formik.errors.ra_city)}
            helperText={formik.touched.ra_city && formik.errors.ra_city}
          />
          <TextField
            disabled
            name="ra_jurisdiction"
            placeholder="Select Province"
            fullWidth
            value={formik.values.ra_jurisdiction}
            onChange={formik.handleChange}
            error={formik.touched.ra_jurisdiction && Boolean(formik.errors.ra_jurisdiction)}
            helperText={formik.touched.ra_jurisdiction && formik.errors.ra_jurisdiction}
          />
          <TextField
            name="ra_postal_code"
            label="Postal Code"
            fullWidth
            value={formik.values.ra_postal_code}
            onChange={formik.handleChange}
            error={formik.touched.ra_postal_code && Boolean(formik.errors.ra_postal_code)}
            helperText={formik.touched.ra_postal_code && formik.errors.ra_postal_code}
            onBlur={formik.handleBlur}
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent={"space-between"} mt={3}>
        <Button variant="contained" color="primary" onClick={handleClickBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickNext}>
          Next
        </Button>
      </Stack>

      {/* <Box marginTop={"50px"}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">Debug</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(formik.values, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
      </Box> */}
      {/* </Formik> */}
    </>
  );
}
