// import reactLogo from "./assets/react.svg";
// import viteLogo from "/vite.svg";
import "@progress/kendo-theme-default/dist/all.css";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Root from "./routes/Root";

import ErrorPage from "./error-page";
import Dashboard from "./pages/Dashboard";
import Login from "./routes/Login";

import { theme } from "./theme.js";
import { AuthProvider } from "./contexts/auth";

import "./App.css";

import NewCorp from "./pages/NewCorp";
import Profile from "./pages/Profile";

import ResetPassword from "./pages/ResetPassword";
import AuthCallback from "./routes/AuthCallback";
import FinishedPaid from "./pages/FinishedPaid";
import ConfirmSignup from "./pages/ConfirmSignup.jsx";

import EntityCreateLayout from "./routes/EntityCreateLayout.jsx";
import EntityView from "./pages/EntityView.jsx";
import Start from "./pages/Start.jsx";

import StripeTest from "./pages/StripeTest.jsx";

//! Mantine core CSS styles
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";
import AuthConfirm from "./routes/AuthConfirm.jsx";
import MagicLinkNextSteps from "./pages/MagicLinkNextSteps.jsx";
import MagicLinkLoginCard from "./routes/MagicLinkLoginCard.jsx";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },

        // {
        //   path: "/create/:eid",
        //   element: <EntityForm />,
        // },

        {
          path: "/newcorp/:eid",
          element: <NewCorp />,
        },
        {
          path: "/view/:eid",
          element: <EntityView />,
        },

        {
          path: "/profile",
          element: <Profile />,
        },

        {
          path: "/finished",
          element: <FinishedPaid />,
        },
      ],
    },
    {
      path: "setup",
      element: <EntityCreateLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "entity/:eid",
          element: <NewCorp />,
          errorElement: <ErrorPage />,
        },
        {
          path: "start",
          element: <Start />,
          errorElement: <ErrorPage />,
        },
      ],
    },

    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/magic-link-next-steps",
      element: <MagicLinkNextSteps />,
    },

    {
      path: "/resetpassword",
      element: <ResetPassword />,
    },
    {
      path: "/auth/callback",
      element: <AuthCallback />,
    },
    {
      path: "/auth/confirm",
      element: <AuthConfirm />,
    },
    {
      path: "/auth/magiclink",
      element: <MagicLinkLoginCard />,
    },
    {
      path: "/confirm-signup",
      element: <ConfirmSignup />,
    },
    {
      path: "/stripetest",
      element: <StripeTest />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <MantineProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </MantineProvider>
    </ThemeProvider>
  );
}

export default App;
