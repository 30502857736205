import { Edit, ExpandMore, SpaceBar } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import supabase from "../config/supabaseClient";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, _ } from "lodash";
import { useAuth } from "../contexts/useAuth";

export default function Step6({ activeStep, setActiveStep, handleNext, handleBack, updateEntityLastStep }) {
  const { eid } = useParams();
  const { user } = useAuth();
  const [entityData, setEntityData] = useState({});
  const [directorData, setDirectorData] = useState([]);
  const [officerData, setOfficerData] = useState([]);
  const [shareholderData, setShareholderData] = useState([]);
  const [shareData, setShareData] = useState([]);
  const [incorporatorData, setIncorporatorData] = useState([]);

  // const fieldBgColor = "#fafafa";
  const fieldBgColor = "#fff";

  const handleClickNext = async (event) => {
    event.preventDefault();

    // FIXME: validate step before this

    // Step specific logic here
    updateEntityLastStep(6);

    handleNext();
  };

  const handleClickBack = () => {
    handleBack();
  };

  const handleClickEditSection = (stepNumber) => {
    console.log("step number:", stepNumber);
    setActiveStep(stepNumber);
  };

  useEffect(() => {
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("got data, not null!");
        console.log("data", data);
        setEntityData(data[0]);
      }
    };

    const getDirectorData = async () => {
      const { data, error } = await supabase.from("director").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("got data, not null!");
        console.log("data", data);
        setDirectorData(data);
      }
    };
    const getOfficerData = async () => {
      const { data, error } = await supabase.from("officer").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only

        console.log("officer data", data);
        setOfficerData(data);
      }
    };
    const getShareholderData = async () => {
      const { data, error } = await supabase.from("shareholder").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        console.log("shareholder data", data);
        setShareholderData(data);

        // Get the share data for this initial shareholder
        const { data: shareData, error: shareError } = await supabase.from("share").select("*").eq("shareholder_id", data[0].id).select();

        if (shareError) {
          console.log("error", error);
          return;
        }
        console.log("share data", shareData);
        setShareData(shareData);
      }
    };

    // Get derived incorporator data from director where is_incorporator = true
    const getIncorporatorData = async () => {
      const { data, error } = await supabase.from("director").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only

        console.log("incorporator data", data);
        const incorporator = data.filter((d) => d.is_incorporator === true);
        console.log("incorporator", incorporator[0]);
        setIncorporatorData(incorporator);
      }
    };

    getEntityData();
    getDirectorData();
    getShareholderData();
    getOfficerData();
    getIncorporatorData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eid]);

  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <Typography mb={3} variant="h4">
          Final Review
        </Typography>
      </Box>
      <Stack rowGap={1} mb={2}>
        <Typography variant="subtitle1">
          Make sure you are happy with everything before clicking the “Launch!” button at the bottom of the screen to sign and submit the Articles of
          Incorporation and make your payment.
        </Typography>
        <Typography variant="subtitle1">
          Don’t worry if you need to change anything after the incorporation. You can always make amendments and change things around.
        </Typography>
        <Typography variant="subtitle1">
          For now, have a look below and if you’re ready, scroll to the bottom of the screen and click “Launch!”
        </Typography>
      </Stack>
      <Stack direction="column" spacing={2} marginBottom={"30px"} justifyContent={"space-between"}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Email
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(3)}>
            <Edit />
          </IconButton>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#a7a7a7"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Offical Business Email:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            {/* <Typography>{user?.email}</Typography> */}
            <Typography>{entityData.official_business_email}</Typography>
          </Box>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Corporation Name
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(0)}>
            <Edit />
          </IconButton>
        </Stack>
        {/* template stack */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Corporation Type:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>{entityData.is_numbered ? "Numbered" : "Named"}</Typography>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Legal Element:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>{entityData?.legal_element_ending}</Typography>
          </Box>
        </Stack>
        {/* General Details */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            General Details
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(1)}>
            <Edit />
          </IconButton>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Business Activity:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>{entityData.naics_description ? entityData.naics_description : "Missing"}</Typography>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Business Code:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>{entityData?.naics_code}</Typography>
          </Box>
        </Stack>

        {/* Fiscal Year End */}
        {/* Address */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Fiscal Year End Month
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(4)}>
            <Edit />
          </IconButton>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Fiscal Year End Month:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>{entityData?.fiscal_year_end_month}</Typography>
          </Box>
        </Stack>

        {/* Address */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Address
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(2)}>
            <Edit />
          </IconButton>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Registered Office Address:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            {entityData.ra_is_standard_address ? (
              <Typography>
                {entityData.ra_street_address ? entityData.ra_street_address : ""} {entityData.ra_street_name ? entityData.ra_street_name : ""}{" "}
                {entityData.ra_city ? entityData.ra_city : ""}, {entityData.ra_jurisdiction ? entityData.ra_jurisdiction : ""}{" "}
                {entityData.ra_country ? entityData.ra_country : ""} {entityData.ra_postal_code ? entityData.ra_postal_code : ""}
              </Typography>
            ) : (
              <Typography>
                {entityData.ra_lot_address ? entityData.ra_lot_address : ""} {entityData.ra_city ? entityData.ra_city : ""},{" "}
                {entityData.ra_jurisdiction ? entityData.ra_jurisdiction : ""} {entityData.ra_country ? entityData.ra_country : ""}{" "}
                {entityData.ra_postal_code ? entityData.ra_postal_code : ""}
              </Typography>
            )}
          </Box>
        </Stack>
        {/* Directors */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Director
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(3)}>
            <Edit />
          </IconButton>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Number of Directors:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>Minimum/Maximum</Typography>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Minimum Number of Directors:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>1</Typography>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          rowGap={1}
          padding={1}
          justifyContent={"start"}
          // border={"solid"}
          // borderColor={"#f1f1f1"}
        >
          <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography variant="subtitle1" fontWeight={600}>
              Maximum Number of Directors:
            </Typography>
          </Box>
          <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
            <Typography>10</Typography>
          </Box>
        </Stack>

        {directorData.map((director, index) => (
          <Stack
            key={index}
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            rowGap={1}
            padding={1}
            justifyContent={"start"}
            // border={"solid"}
            // borderColor={"#f1f1f1"}
          >
            <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography variant="subtitle1" fontWeight={600}>
                {directorData.length <= 1 ? "Director:" : `Director ${index + 1}`}
              </Typography>
            </Box>
            <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography>
                {director.first_name} {director.last_name}
              </Typography>
              <Typography>{director.email}</Typography>
              <Typography>Canadian Resident: {director.is_canadian_resident ? "Yes" : "No"}</Typography>
              <Typography fontWeight={500} marginTop={1}>
                Business Address
              </Typography>
              <Typography>
                {director.b_is_standard_address
                  ? `${director.b_street_address} ${_.startCase(director.b_street_name)} ${_.startCase(director.b_city)}, ${_.startCase(
                      director.b_jurisdiction
                    )} ${_.startCase(director.b_country)} ${director.b_postal_code.toUpperCase()}`
                  : `${director.b_lot_address} `}
              </Typography>

              <Typography fontWeight={500} marginTop={1}>
                Residential Address
              </Typography>
              <Typography>
                {director.r_is_standard_address
                  ? `${director.r_street_address} ${_.startCase(director.r_street_name)} ${_.startCase(director.r_city)}, 
                ${_.startCase(director.r_jurisdiction)} ${_.startCase(director.r_country)} ${director.r_postal_code.toUpperCase()}`
                  : `${director.r_lot_address} `}
              </Typography>
            </Box>
          </Stack>
        ))}
        {/* Incorporators */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Incorporator
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(3)}>
            <Edit />
          </IconButton>
        </Stack>

        {incorporatorData.map((incorporator, index) => (
          <Stack
            key={index}
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            rowGap={1}
            padding={1}
            justifyContent={"start"}
            // border={"solid"}
            // borderColor={"#f1f1f1"}
          >
            <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography variant="subtitle1" fontWeight={600}>
                {incorporatorData.length <= 1 ? "Incorporator:" : `Director ${index + 1}`}
              </Typography>
            </Box>
            <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography>
                {incorporator.first_name} {incorporator.last_name}
              </Typography>
              <Typography>{incorporator.email}</Typography>

              <Typography fontWeight={500} marginTop={1}>
                Address
              </Typography>
              <Typography>
                {incorporator.r_is_standard_address
                  ? `${incorporator.r_street_address} ${_.startCase(incorporator.r_street_name)} ${_.startCase(incorporator.r_city)},
                ${_.startCase(incorporator.r_jurisdiction)} ${_.startCase(incorporator.r_country)} ${incorporator.r_postal_code.toUpperCase()}`
                  : `${incorporator.r_lot_address} `}
              </Typography>
            </Box>
          </Stack>
        ))}

        {/* Shareholders */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Shareholder
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(3)}>
            <Edit />
          </IconButton>
        </Stack>

        {shareholderData.map((shareholder, index) => (
          <Stack
            key={index}
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            rowGap={1}
            padding={1}
            justifyContent={"start"}
            // border={"solid"}
            // borderColor={"#f1f1f1"}
          >
            <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography variant="subtitle1" fontWeight={600}>
                {shareholderData.length <= 1 ? "Shareholder:" : `Shareholder ${index + 1}`}
              </Typography>
            </Box>
            <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography>
                {shareholder.first_name} {shareholder.last_name}
              </Typography>
              <Typography>{shareholder.email}</Typography>

              <Typography fontWeight={500} marginTop={1}>
                Address
              </Typography>
              <Typography>
                {shareholder.is_standard_address
                  ? `${shareholder.street_address} ${_.startCase(shareholder.street_name)} ${_.startCase(shareholder.city)},
                ${_.startCase(shareholder.jurisdiction)} ${_.startCase(shareholder.country)} ${shareholder.postal_code.toUpperCase()}`
                  : `${shareholder.lot_address} `}
              </Typography>
              <Typography fontWeight={500} marginTop={1}>
                Number of Shares:
              </Typography>
              <Typography>{shareData[0]?.qty}</Typography>
              <Typography fontWeight={500} marginTop={1}>
                Per Share value ($):
              </Typography>
              <Typography>{entityData.per_share_value}</Typography>
            </Box>
          </Stack>
        ))}

        {/* Officers */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} paddingTop={2} justifyContent={"space-between"}>
          <Typography variant="h6" fontWeight={600} color={"primary"}>
            Officer
          </Typography>
          <IconButton aria-label="edit" color="primary" onClick={() => handleClickEditSection(3)}>
            <Edit />
          </IconButton>
        </Stack>

        {officerData.map((officer, index) => (
          <Stack
            key={index}
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            rowGap={1}
            padding={1}
            justifyContent={"start"}
            // border={"solid"}
            // borderColor={"#f1f1f1"}
          >
            <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography variant="subtitle1" fontWeight={600}>
                {officerData.length <= 1 ? "Officer:" : `Officer ${index + 1}`}
              </Typography>
            </Box>
            <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
              <Typography>
                {officer.first_name} {officer.last_name}
              </Typography>

              <Typography fontWeight={500}>{officer.title}</Typography>

              <Typography>{officer.email}</Typography>

              <Typography fontWeight={500} marginTop={1}>
                Address
              </Typography>
              <Typography>
                {officer.is_standard_address
                  ? `${officer.street_address} ${_.startCase(officer.street_name)} ${_.startCase(officer.city)}, ${_.startCase(officer.jurisdiction)}
                ${_.startCase(officer.country)} ${officer.postal_code.toUpperCase()}`
                  : `${officer.lot_address} `}
              </Typography>
            </Box>
          </Stack>
        ))}

        {/* {Array.isArray(shareholderData) ? (
          <>
            {shareholderData.map((shareholder, index) => (
              <Stack
                key={index}
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                rowGap={1}
                padding={1}
                justifyContent={"start"}
                border={"solid"}
                borderColor={"#f1f1f1"}
              >
                <Box width={{ sm: "30%", md: "250px" }} padding={1} color={"black"} bgcolor={fieldBgColor}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {shareholderData.length <= 1 ? "Shareholder:" : `Shareholder {index + 1}`}
                  </Typography>
                </Box>
                <Box flexGrow={3} padding={1} color={"black"} bgcolor={fieldBgColor}>
                  <Typography>
                    {shareholder.first_name} {shareholder.last_name}
                  </Typography>
                  <Typography>{shareholder.email}</Typography>

                  <Typography fontWeight={500} marginTop={1}>
                    Address for Service
                  </Typography>
                  <Typography>
                    {shareholder.street_address} {_.startCase(shareholder.street_name)} {_.startCase(shareholder.city)},{" "}
                    {_.startCase(shareholder.jurisdiction)} {_.startCase(shareholder.country)} {shareholder.postal_code.toUpperCase()}{" "}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </>
        ) : (
          <Skeleton animation="wave" />
        )} */}
        {/* step navigation */}
      </Stack>
      <Stack direction="row" spacing={2} justifyContent={"space-between"}>
        <Button variant="contained" color="primary" onClick={handleClickBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickNext}>
          Next
        </Button>
      </Stack>

      {/* <Box marginTop={"50px"}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">Debug</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>entity:{JSON.stringify(entityData, null, 2)}</pre>
            <pre>director:{JSON.stringify(directorData, null, 2)}</pre>
            <pre>officer:{JSON.stringify(officerData, null, 2)}</pre>
            <pre>shareholder:{JSON.stringify(shareholderData, null, 2)}</pre>
            <pre>shareData:{JSON.stringify(shareData, null, 2)}</pre>
            <pre>incorporator:{JSON.stringify(incorporatorData, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
      </Box> */}
    </>
  );
}
