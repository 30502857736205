import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Stack, TextField, Typography } from "@mui/material";

import { useFormik } from "formik";
import * as yup from "yup";

import { HelpPopover } from "../components/HelpPopover";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../contexts/useAuth";
import supabase from "../config/supabaseClient";
import { useNavigate, useParams } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

import { CircularProgress } from "@mui/material";
// import { set } from "lodash";
import { handleDeleteFile } from "../utilities/sbtools";
import SignaturePad from "../components/signaturepad/SignaturePad";

const validationSchema = yup.object({
  first_name: yup.string("Enter your first name").required("First name is required"),
  last_name: yup.string("Enter your last name").required("Last name is required"),
  address: yup.string("Enter your address").required("Address is required"),
  city: yup.string("Enter your city").required("City is required"),
  postal_code: yup.string("Enter your postal code").required("Postal code is required"),
  province: yup.string("Enter your province").required("Province is required"),
  country: yup.string("Enter your country").required("Country is required"),
  phone: yup.string("Enter your phone").required("Phone is required"),
  card_number: yup.string("Enter your card number").required("Card number is required"),
  expiry_month: yup.string("Enter your expiry month").required("Expiry month is required"),
  expiry_year: yup.string("Enter your expiry year").required("Expiry year is required"),
  cvv: yup.string("Enter your cvv").required("CVV is required"),
});

const product = 699;
const annualMaintenance = 19.99;
const taxrate = 0.13;

// const dev_url = "http://localhost:4000/gpup/transaction";
const dev_url = "http://localhost:4000/checkout";
// const prod_url = "https://skylaunch-api-globalpayments-production.up.railway.app/gpup/transaction";
const prod_url = "https://skylaunch-api-globalpayments-production.up.railway.app/checkout";

const api_url = window.location.hostname === "localhost" ? dev_url : prod_url;

console.log(api_url);

export default function Payment({ activeStep, setActiveStep, handleNext, handleBack, updateEntityLastStep }) {
  const { eid } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [selectedAnnualMaintenance, setSelectedAnnualMaintenance] = useState(false);

  const [subtotal, setSubtotal] = useState(product);
  // const [taxTotal, setTaxTotal] = useState(subtotal * taxrate);
  // const [total, setTotal] = useState(subtotal + taxTotal);

  // memoized values
  const taxTotal = useMemo(() => subtotal * taxrate, [subtotal]);
  const total = useMemo(() => subtotal + taxTotal, [subtotal, taxTotal]);
  const gpAmount = useMemo(() => total.toFixed(2).replace(".", ""), [total]);

  const processPayment = async (values) => {
    // make POST request to payment gateway
    // if success, return { status: "success" }
    // if failure, return { status: "failure" }

    //   {
    //     "name":"Any name for this transaction",
    //     "first_name": "Jane",
    //     "last_name":"Doe",
    //     "amount":"2300",
    //     "card_number":"4012002000060016",
    //     "expiry_month":"12",
    //     "expiry_year":"25",
    //     "cvv":"123",
    //     "avs_address":"123 Anywhere ave.",
    //     "avs_postal_code":"l6a5a4"
    // }

    // build the payload object to send to the checkout orchestrator service
    console.log(typeof entityData.read_only);

    const payload = {
      req_entity_name: entityData.english_name,
      legal_element_ending: entityData.legal_element_ending,
      has_nuans_report: entityData.has_nuans_report,
      is_numbered: entityData.is_numbered,
      entity_id: eid,
      selectedAnnualMaintenance: selectedAnnualMaintenance,

      name: "Any name for this transaction",
      first_name: values.first_name,
      last_name: values.last_name,
      amount: values.amount,
      card_number: values.card_number,
      expiry_month: values.expiry_month,
      expiry_year: values.expiry_year,
      cvv: values.cvv,
      avs_address: values.address,
      avs_postal_code: values.postal_code,
    };

    if (selectedAnnualMaintenance) {
      console.log("adding annual maintenance");
    }

    console.log("payload", payload);

    const headers = {
      "Content-Type": "application/json",
    };

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(api_url, requestOptions);
      const data = await response.json();
      console.log("data: ", data);
      console.log("status", data.status);

      // if (data.status === "failed") {
      //   return { status: data.status };
      // }

      // if (data.status === "success") {
      //   alert("Payment successful");
      //   navigate("/finished");
      // }
      return { status: data.status };
    } catch (error) {
      console.log("catch error: ", error);
      return { status: "failure" };
    }

    // return { status: "success" };
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      first_name: "Marc",
      last_name: "LP",
      address: "103 Lebovic Campus Drive",
      city: "Maple",
      postal_code: "l6a5a4",
      province: "ontario",
      country: "Canada",
      phone: "6474620281",
      card_number: "4012002000060016",
      expiry_month: "12",
      expiry_year: "25",
      cvv: "123",
      amount: "0",
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      // alert(JSON.stringify(values, null, 2));

      // const amount = {...values, amount: gpAmount}

      console.log("Processing payment ....");

      // process will contain the return value from the checkout orchestrator service: checkout.process
      const process = await processPayment({ ...values, amount: gpAmount });
      console.log("process: ", process);
      if (process.status === "success") {
        setIsSubmitting(false);
        // alert("Payment successful");
        console.log("payment successful");
        navigate("/finished");
      }

      if (process.status === "failure") {
        alert("Payment failed");
        setIsSubmitting(false);
      }
    },
  });

  // Currently unused
  const handleClickNext = async (event) => {
    event.preventDefault();

    // FIXME: validate step before this

    // Step specific logic here
    console.log("updating entity", eid, entityData.fiscal_year_end_month);

    const { data, error } = await supabase
      .from("entity")
      .update({
        fiscal_year_end_month: entityData.fiscal_year_end_month,
      })
      .eq("id", eid)
      .single();

    if (data) {
      console.log(data);
    }

    if (error) {
      console.log(error);
    }
    updateEntityLastStep(5);
    handleNext();
  };

  const handleClickBack = () => {
    handleBack();
  };

  const handleSelectedAnnualMaintenance = () => {
    setSelectedAnnualMaintenance((prev) => !prev);
  };

  useEffect(() => {
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("got data, not null!");
        console.log("data", data);
        setEntityData(data[0]);
      }
    };

    getEntityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubtotal(selectedAnnualMaintenance ? product + annualMaintenance : product);
    // formik.setValues({ ...values, amount: total.toFixed(2).replace(".", "") });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnualMaintenance, product, annualMaintenance]);

  return (
    <>
      <Typography mb={3} variant="h5">
        Payment
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} rowGap={3} columnGap={3} alignItems={"start"} mb={3}>
          <Box display={"flex"} width={{ xs: "100%", md: "60%" }} flexDirection={"column"} rowGap={2}>
            <Typography variant="subtitle1">Billing Details</Typography>
            <TextField
              fullWidth
              id="first_name"
              name="first_name"
              label="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
            <TextField
              fullWidth
              id="last_name"
              name="last_name"
              label="Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
            <TextField
              fullWidth
              id="address"
              name="address"
              label="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
            <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} rowGap={3} columnGap={3} alignItems={"center"}>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
              <TextField
                fullWidth
                id="postal_code"
                name="postal_code"
                label="Postal Code"
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
                helperText={formik.touched.postal_code && formik.errors.postal_code}
              />
            </Box>
            <TextField
              fullWidth
              id="province"
              name="province"
              label="Province / State"
              value={formik.values.province}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.province && Boolean(formik.errors.province)}
              helperText={formik.touched.province && formik.errors.province}
            />
            <TextField
              fullWidth
              id="country"
              name="country"
              label="Country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <Typography variant="subtitle1" marginTop={3}>
              Card Details
            </Typography>
            <TextField
              fullWidth
              id="card_number"
              name="card_number"
              label="Card Number"
              value={formik.values.card_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.card_number && Boolean(formik.errors.card_number)}
              helperText={formik.touched.card_number && formik.errors.card_number}
            />
            <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} rowGap={3} columnGap={3} alignItems={"start"}>
              <TextField
                fullWidth={true}
                id="expiry_month"
                name="expiry_month"
                label="Expiry Month"
                value={formik.values.expiry_month}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.expiry_month && Boolean(formik.errors.expiry_month)}
                helperText={formik.touched.expiry_month && formik.errors.expiry_month}
              />
              <TextField
                fullWidth
                id="expiry_year"
                name="expiry_year"
                label="Expiry Year"
                value={formik.values.expiry_year}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.expiry_year && Boolean(formik.errors.expiry_year)}
                helperText={formik.touched.expiry_year && formik.errors.expiry_year}
              />
              <TextField
                id="cvv"
                name="cvv"
                label="CVV"
                value={formik.values.cvv}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                helperText={formik.touched.cvv && formik.errors.cvv}
              />
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} width={{ xs: "100%", md: "40%" }} columnGap={3}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              padding={3}
              border={1}
              borderColor={"lightgray"}
              bgcolor={"#3A589620"}
              borderRadius={1}
              gap={2}
            >
              <Typography variant="h6">Incorporation</Typography>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Typography variant="h5">$699</Typography>
              </Box>
              <Typography variant="body2">Named or Numbered corporation</Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              minHeight={"100px"}
              padding={3}
              border={1}
              borderColor={"lightgray"}
              marginTop={2}
              borderRadius={1}
              gap={2}
              {...(selectedAnnualMaintenance && { bgcolor: "#3A589620" })}
            >
              <Typography variant="h5">Annual Maintenance</Typography>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                  <Checkbox color="primary" value={selectedAnnualMaintenance} onChange={handleSelectedAnnualMaintenance} />
                  <Typography variant="h6">$19.99/month</Typography>
                </Box>
                <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
                  <Typography variant="body2">Billed Monthly</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              padding={3}
              border={1}
              borderColor={"lightgray"}
              marginTop={2}
              borderRadius={1}
              // bgcolor={"lightgrey"}
            >
              {/* Cost Container */}
              <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={2}>
                {/* Left */}
                <Box display={"flex"} width={"100%"} flexDirection={"column"} alignItems={"start"} marginTop={2}>
                  <Typography variant="h6">Subtotal:</Typography>
                  <Typography variant="h6">HST:</Typography>
                  <Typography variant="h6" fontWeight={"700"}>
                    Total:
                  </Typography>
                </Box>
                {/* Right */}
                <Box display={"flex"} flexDirection={"column"} alignItems={"end"} marginTop={2}>
                  <Typography variant="h6">${subtotal.toFixed(2)}</Typography>
                  <Typography variant="h6">${taxTotal.toFixed(2)}</Typography>
                  <Box display={"flex"} borderTop={0} borderColor={"lightgray"}>
                    <Typography variant="h6" fontWeight={"700"} align="right">
                      ${total.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={2}>
                test
              </Box>
            </Box>
          </Box>
        </Box>
        <Stack display={"flex"} flexDirection={{ xs: "column", sm: "row" }} rowGap={3} columnGap={3} justifyContent={"space-between"} mb={3}>
          <Button variant="contained" color="primary" onClick={handleClickBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            endIcon={isSubmitting && <CircularProgress size={20} sx={{ color: "#ffffff" }} />}
          >
            Confirm Order
          </Button>
        </Stack>
      </form>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={3}
        border={1}
        borderColor={"lightgray"}
        marginTop={2}
        borderRadius={1}
        // bgcolor={"lightgrey"}
      >
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={2}>
          <SignaturePad />
        </Box>
      </Box>

      <Stack direction="row" spacing={2} justifyContent={"space-between"} marginY={2}></Stack>

      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1">Debug</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>formik:{JSON.stringify(formik.values, null, 2)}</pre>
          <pre>formik errors:{Object.keys(formik.errors).length}</pre>
          <pre>entity:{JSON.stringify(entityData, null, 2)}</pre>
          <pre>user: {JSON.stringify(user, null, 2)}</pre>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
}
