import { Verified } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";

const annual_maintenance_features = [
  "Access to Team of Experienced Law Clerks",
  "Share Access to Online Minute Book (Coming Soon)",
  "Annual Return Preparation and Filing",
  "Annual Shareholder Resolutions (standard form)",
  "Annual Director Resolutions (standard form)",
  "Update to Corporate Registers",
];

export default function annualMaintFeatureBlock() {
  return (
    // <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
    // <CardContent>
    <Stack direction={{ xs: "column", lg: "row" }} spacing={4} display={"flex"} justifyContent={"start"} alignItems={{ xs: "center", lg: "start" }}>
      <img src="/header/user-group.svg" width={250} />
      <Stack direction="column" spacing={2}>
        <Typography variant="h5" fontWeight={500}>
          Skylaunch Membership (Annual)
        </Typography>
        {/* <Typography>Annual Membership includes:</Typography> */}
        {annual_maintenance_features.map((feature, index) => {
          return (
            <Stack key={index} direction="row" spacing={2} display={"flex"} justifyContent={"start"}>
              <Verified color="primary" />
              <Typography fontSize={{ xs: "12px", lg: "16px" }}>{feature}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
    // </CardContent>
    // </Card>
  );
}
