import { Box, Button, Card, CardContent, Grid, Modal, Skeleton, Stack, TextField, Typography, styled } from "@mui/material";
import supabase from "../config/supabaseClient";
// import { useAuth } from "../contexts/useAuth";;
import { useAuth } from "../contexts/useAuth";
import Entity from "../components/Entity";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
// import { useOutletContext } from "react-router-dom";

const schemaCreateNewEntity = Yup.object().shape({
  entityName: Yup.string().required("Required"),
});

const StyledModal = styled(Modal)({
  display: "flex",
  top: "-50%",

  alignSelf: "center",
  alignItems: "center",
  // maxWidth: "400px",
  justifyContent: "center",
});

export default function Dashboard({ entity_data }) {
  // context value from root.jsx via react-router-dom
  // const [selectedEntity, setSelectedEntity] = useOutletContext();
  const { user } = useAuth();
  const [entities, setEntities] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [openModelCreateEntity, setOpenModelCreateEntity] = useState(false);

  const handleOpenModelCreateEntity = () => setOpenModelCreateEntity(true);
  const handleCloseModelCreateEntity = () => setOpenModelCreateEntity(false);

  const navigate = useNavigate();

  function startEntityCreate() {
    navigate("/setup/start");
  }
  // formik config
  const formik = useFormik({
    initialValues: {
      entityName: "",
    },
    validationSchema: schemaCreateNewEntity,
    onSubmit: async (values) => {
      console.log("values", values);
      //supabase create entity here...

      const { data, error } = await supabase
        .from("entity")
        .insert([
          {
            name: values.entityName,
            linked_user: user.id,
            status: "In-Progress",
          },
        ])
        .select();
      if (error) {
        console.log("error", error);
      }

      if (data) {
        console.log("data", data);
        handleRefreshEntities(data[0]);
        handleCloseModelCreateEntity();
        // TODO: navigate to new entity
      }
    },
  });

  useEffect(() => {
    // Get entities from Supabase
    const fetchEntities = async () => {
      setIsLoading(true);

      // await user;
      // console.log("user", user);
      // const { data, error } = await supabase.from("entity").select("*").eq("linked_user", user_id).order("name", { ascending: true });
      const { data, error } = await supabase.from("entity").select("*").order("created_at", { ascending: false });

      if (error) console.log("error", error);

      if (data) {
        // console.log("Entities:", data);
        setEntities(data);
        setIsLoading(false);
      }
    };
    fetchEntities();

    // console.log("Dashboard: ", entities);
  }, [user]);

  const handleRefreshEntities = (newEntity) => {
    // console.log("The new entity: ", newEntity);
    // * Refresh the entities state list to include the new entity just created
    setEntities((previousEntities) => [...previousEntities, newEntity]);
    // console.log("entities post add: ", entities);
  };

  const LoadingSkeleton = () => {
    return (
      <Stack direction="row" flexWrap={"wrap"} spacing={2}>
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      </Stack>
    );
  };

  return (
    <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}>
      <Box flex={2}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2} display={"flex"} justifyContent={"space-between"} padding={2}>
          <Typography variant="h5" fontWeight={500} textAlign={"left"} color={"black"}>
            My Companies
          </Typography>

          <Box>
            <Button variant="contained" color="primary" onClick={startEntityCreate}>
              Start a Corporation
            </Button>
          </Box>
        </Stack>

        {/* <TransitionGroup> */}
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          // <Card sx={{ margin: 3 }} elevation={2}>
          //   <CardContent>
          <Grid display={"flex"} container spacing={2} marginTop={2} marginLeft={1} marginRight={1}>
            {/* <Fade in={pageLoaded}> */}
            <AnimatePresence>
              {entities.map((entity, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Entity key={entity.id} entity={entity} />
                </motion.div>
              ))}
            </AnimatePresence>
            {/* </Fade> */}
          </Grid>
          //   </CardContent>
          // </Card>
        )}
        {/* </TransitionGroup> */}
        {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
      </Box>

      {/* Modal: Create Corporation */}
      <StyledModal open={openModelCreateEntity} onClose={handleCloseModelCreateEntity}>
        <Box bgcolor={"white"} minWidth={300} minHeight={200} padding={3}>
          <Typography variant="h5" textAlign={"center"} marginBottom={3}>
            Start a Corporation
          </Typography>
          <Typography variant="body1" maxWidth={400} marginBottom={3}>
            Add a name for your corporation, this is just a friendly name for use only in this app. We will be able to choose a &apos;named&apos; or
            &apos;numbered&apos; corporation in the next step.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                name="entityName"
                value={formik.values.entityName}
                onChange={formik.handleChange}
                error={formik.touched.entityName && Boolean(formik.errors.entityName)}
                helperText={formik.touched.entityName && formik.errors.entityName}
                label="Friendly Name"
                fullWidth
              />
              <Button type="submit" variant="contained" color="primary">
                Create
              </Button>
            </Stack>
          </form>
        </Box>
      </StyledModal>
    </Box>
  );
}
