import { Toolbar, Typography, styled, Box, Stack, Divider, List, ListItemButton, ListItem, ListItemText, Drawer } from "@mui/material";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
// import { useAuth } from "../contexts/useAuth";;
import { useAuth } from "../contexts/useAuth";
import { useEffect, useState } from "react";

import NavBar from "../components/NavBar";
import supabase from "../config/supabaseClient";
import dayjs from "dayjs";
import { Toaster } from "react-hot-toast";
import { AnimatePresence, motion } from "framer-motion";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const drawerWidth = 240;

export default function Root() {
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  // Drawer related
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box sx={{ backgroundColor: "steelblue" }}>
      {/* <Toolbar /> */}
      <Box py={1}>
        <img src="/skylaunch_icon_white.svg" alt="Sky Launch Logo" width="80px" height="50px" onClick={() => navigate("/dashboard")} />
      </Box>
      <Divider />
      <Typography variant="h6" fontWeight={600} color={"white"} component="div" sx={{ p: 1 }}>
        My Companies
      </Typography>
      <List>
        {["Company 1", "Company 2", "Company 3", "Company 4"].map((text, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} sx={{ color: "white" }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const textParamType = params.get("type");
  console.log(textParamType);

  if (textParamType === "recovery") {
    navigate("/resetpassword");
  }

  // menu open and close state
  const [open, setOpen] = useState(false);

  function handleClick(event) {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setOpen(false);
    setAnchorEl(null);
    alert("You clicked a menu item");
  }

  function handleSelectEntity(eid) {
    console.log("eid", eid);
    setSelectedEntity(eid);
    navigate(`/view/${eid}`);
  }

  function handleReturnToDashboard() {
    console.log("returning to dashboard...");
    setSelectedEntity("");
    navigate("/dashboard");
  }

  useEffect(() => {
    // console.log(params);
    // console.log("user:", user);
    if (!user) {
      // alert("You must be logged in to view this page");
      navigate("/login");
    } else {
      // Causes user to be redirected to dashboard when they are logged in on re-render
      // navigate("/dashboard");
      console.log("redirecting to dashboard...");
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      // Get entities from Supabase
      const fetchEntities = async () => {
        setIsLoading(true);

        // const user_id = user.id;
        // await user;
        // console.log("user", user);
        // const { data, error } = await supabase.from("entity").select("*").eq("linked_user", user_id).order("name", { ascending: true });
        const { data, error } = await supabase.from("entity").select("*").order("created_at", { ascending: false });

        if (error) console.log("error", error);

        if (data) {
          console.log("data", data);
          setEntities(data);
          setIsLoading(false);
        }
      };
      fetchEntities();
    }

    // console.log("Dashboard: ", entities);
  }, [user]);

  return (
    <>
      {/* move the appbar to a separate component */}
      {/* <AppBar position="sticky" sx={{ bgcolor: "white", borderRadius: 5, boxShadow: "none" }}>
        <StyledToolbar sx={{ justifyContent: "space-between" }}>
          <img src="/skylaunch_logo_blue.svg" alt="Sky Launch Logo" width="200px" height="80px" onClick={() => navigate("/dashboard")} />

          <Icons>
            <Badge badgeContent={4} color="secondary">
              <Notifications color="primary" />
            </Badge>
            <Avatar id="avatar" onClick={handleClick}>
              {user?.user_metadata?.name_first[0] + user?.user_metadata?.name_last[0]}
            </Avatar>
          </Icons>
          <UserBox>
            <Avatar id="avatar" onClick={handleClick}>
              {user?.user_metadata?.name_first[0]}
            </Avatar>
          </UserBox>
        </StyledToolbar>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={() => setOpen(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ mt: 5 }}
        >
          <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
          <MenuItem onClick={() => navigate("/billing")}>Billing</MenuItem>
          <MenuItem onClick={() => signOut()}>Logout</MenuItem>
        </Menu>
      </AppBar> */}
      {/* end of nav bar */}
      {/* <Container disableGutters> */}
      <div>
        {/* <Outlet context={[selectedEntity, setSelectedEntity]} /> */}
        <Box id="drawer-root" sx={{ display: "flex" }}>
          <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="left navigation">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              // container={container}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, height: "100%", backgroundColor: "steelblue" },
              }}
            >
              {/* {drawer} */}
              <Box sx={{ backgroundColor: "steelblue" }}>
                {/* <Toolbar /> */}
                <Box p={1} sx={{ display: "flex", justifyContent: "start" }}>
                  <img src="/skylaunch_logo_white.svg" alt="Sky Launch Logo" width="150px" height="50px" onClick={() => navigate("/dashboard")} />
                </Box>
                <Divider />
                {/* <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleReturnToDashboard}>
                      <Typography fontWeight={600} color={"white"} sx={{ p: 0, fontsize: 13 }}>
                        Dashboard
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </List> */}
                <Typography variant="h6" fontWeight={600} color={"white"} component="div" sx={{ p: 1 }}>
                  My Companies
                </Typography>
                <List>
                  <AnimatePresence>
                    {entities.map((entity, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                      >
                        <ListItem key={entity.id} disablePadding>
                          <ListItemButton onClick={() => handleSelectEntity(entity.id)}>
                            {/* <ListItemText primary={entity.name} sx={{ color: "white" }} /> */}
                            <Typography fontWeight={600} color={"white"} sx={{ p: 0, fontSize: 13 }}>
                              {entity.name} {dayjs(entity.created_at).format("MMM/DD/YYYY")}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </List>
              </Box>
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, height: "100%", backgroundColor: "steelblue" },
              }}
              open
            >
              {/* {drawer} */}

              <Box sx={{ backgroundColor: "steelblue" }}>
                {/* <Toolbar /> */}
                <Box p={1} sx={{ display: "flex", justifyContent: "center" }}>
                  <img src="/skylaunch_logo_white.svg" alt="Sky Launch Logo" width="150px" height="50px" onClick={() => navigate("/dashboard")} />
                </Box>
                <Divider />
                {/* <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleReturnToDashboard}>
                      <Typography fontWeight={600} color={"white"} sx={{ p: 0, fontsize: 13 }}>
                        Dashboard
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </List> */}
                <Typography variant="subtitle1" fontWeight={600} color={"white"} component="div" sx={{ p: 1 }}>
                  My Companies
                </Typography>
                <List>
                  <AnimatePresence>
                    {entities.map((entity, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5, delay: index * 0.125 }}
                      >
                        <ListItem key={entity.id} disablePadding sx={selectedEntity === entity.id ? { bgcolor: "#6b9bc3" } : null}>
                          <ListItemButton onClick={() => handleSelectEntity(entity.id)}>
                            <Stack direction="column" spacing={0}>
                              <Typography fontWeight={600} color={"white"} sx={{ p: 0, fontSize: 13 }}>
                                {entity.name}
                              </Typography>
                              <Typography fontWeight={300} color={"white"} sx={{ p: 0, fontSize: 10 }}>
                                Created: {dayjs(entity.created_at).format("MMM/DD/YYYY")}
                              </Typography>
                            </Stack>
                          </ListItemButton>
                        </ListItem>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </List>
              </Box>
            </Drawer>
          </Box>
          <Stack id="stack-nav-outlet" spacing={2} direction={"column"} sx={{ width: "100%", height: "100vh", overflowY: "auto" }}>
            <NavBar handleDrawerToggle={handleDrawerToggle} handleReturnToDashboard={handleReturnToDashboard} />
            <Outlet />
          </Stack>
        </Box>
        {/* </Container> */}
      </div>
      <Toaster />
    </>
  );
}
