export function replaceSpacesWithOr(sentence) {
  return sentence.replace(/\s/g, " | ");
}

export function replaceSpacesWithAnd(sentence) {
  return sentence.replace(/\s/g, " & ");
}

export function uppercaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function upperCaseAllWords(str) {
  return str
    .replace(/-/g, " ")
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
}

export function entityStatusColor(status) {
  console.log("status: ", status);
  switch (status.toLowerCase()) {
    case "new":
      return "entityStatuses.new";
    case "completed":
      return "entityStatuses.completed";
    case "in-progress":
      return "entityStatuses.inProgress";
    case "processing":
      return "entityStatuses.processing";
    default:
      return "entityStatuses.none";
  }
}

export function convertTimeStampToDate(timestamp) {
  return new Date(timestamp * 1000).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
